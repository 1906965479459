@import '/theme';

.ui.input.error > input {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ui.input.error > input::-webkit-input-placeholder {
  color: #e7bdbc;
}
.ui.input.error > input::-moz-placeholder {
  color: #e7bdbc;
}
.ui.input.error > input:-ms-input-placeholder {
  color: #e7bdbc !important;
}
.ui.input.error > input:focus::-webkit-input-placeholder {
  color: #da9796;
}
.ui.input.error > input:focus::-moz-placeholder {
  color: #da9796;
}
.ui.input.error > input:focus:-ms-input-placeholder {
  color: #da9796 !important;
}

.FileDrop {
  width: 100%;
  padding: 35px;
  text-align: center;
  border: 2px dashed $darkGrey;
  margin-bottom: 20px;
}

.fileContainer {
  box-shadow: none;
}

.imageUploaderError {
  display: none;
}

.dropdown {
  &.search {
    &.positionIcon {
      .icon {
        line-height: 12px !important;
      }
    }
  }
}

.ChecklistTemplate {
  width: 100%;

  .AddTask {
    .input {
      margin-bottom: 0;
    }
  }
}

.JobScroller {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  background: $offWhite;
  border: 1px solid $primary_colour;
  border-radius: 5px;
  margin-right: 20px;
  overflow: hidden;

  min-width: 200px;

  display: flex;
  flex-direction: column;

  > button {
    background: none;
    outline: none;
    border: none;

    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif !important;
    font-weight: 800;

    transition: 0.2s background, 0.2s color;

    padding: 0.75em 0;

    &:hover {
      background: $secondary_colour;
      color: $white;
      cursor: pointer;
    }
  }

  > .divider {
    width: 60%;
    margin: 10px auto;
    border-bottom: 1px solid $primary_colour;
  }
}

.JobScrollableForm {
  display: flex !important;

  .form {
    height: 100%;
  }
}

.DataAvailabilityPickerWrapper {
  display: flex;
}

.DataAvailabilityWrapper {
  height: calc(100% - 10px);
  background: $white;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  margin-left: 0.5em;
}

.DataAvailabilityHeader {
  background: $red;
  color: $white;
  padding: 0.5em;
  border-bottom: 1px solid #aeaeae;
  text-align: center;
  font-weight: 800;
}

.DataAvailabilityBody {
  overflow-y: auto;
  height: 175px;

  > div {
    > div {
      border-bottom: 1px solid #aeaeae;
      padding: 0.5em;

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;

        > svg {
          color: $darkRed;
          margin-right: 0.5em;
        }

        > p {
          font-weight: 800;
          font-size: 0.9em;
          padding: 0;
          margin: 0;
        }

        > p.bold {
          font-weight: 800;
        }
      }
    }

    > div:last-of-type {
      border: none;
    }
  }
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  background-color: $red;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #216ba5;
}
