@import '/theme';

.ui.modal {
  width: 1600px !important;
  max-width: 90% !important;
}

.DataModalContainer {
  width: 1600px !important;
  max-width: 90% !important;

  border-radius: 0.28571429rem !important;

  & > .header {
    background: $primary_colour !important;
    color: $white !important;
    border-bottom: none !important;
  }

  .content {
    max-height: calc(80vh - 5em) !important;
    background: $white !important;

    .ModalLinksWrapper {
      display: flex;

      .ModalLinks {
        width: 200px;
        background: $white;
        border-radius: 0.28571429rem;
        border: 1px solid $primary_colour;
        padding: 20px;
        margin-right: 20px;
      }
    }

    .DataFormSection {
      background: $white;
      border-radius: 0.28571429rem;
      border: 1px solid $primary_colour;
      padding: 20px;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .DataFormSectionTitleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        svg {
          color: $tertiary_colour;
          font-size: 20px;
          margin-right: 15px;
        }

        h1 {
          color: $tertiary_colour;
          font-size: 18px;
          margin: 0;
        }
      }

      .DataFormSectionFields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .field {
          width: 100%;

          @media (min-width: 769px) {
            &.halfWidth {
              width: 49%;
            }

            &.thirdWidth {
              width: 32%;
            }

            &.quarterWidth {
              width: 25%;
            }
          }
        }
      }

      .ui.horizontal.divider {
        margin: 24px 0 20px;
      }

      .DataTableContainer {
        margin-top: 20px;

        .DataTableTitleContainer {
          svg {
            font-size: 20px;
            margin-right: 15px;
          }

          h1 {
            font-size: 18px;
          }
        }

        button.ui.small.icon.left.labeled.button {
          width: 100% !important;

          &.spaceBelow {
            margin-bottom: 1em;
          }
        }
      }

      .field:first-of-type {
        & > .DataTableContainer {
          margin-top: 0;
        }
      }
    }

    .DataFormTabContainer {
      border: 1px solid $primary_colour;
      border-radius: 5px;
      margin-bottom: 20px;

      .DataFormSectionTitleContainer {
        padding: 20px;

        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;

        &:first-of-type {
          padding-bottom: 0;
          margin-bottom: 0;
        }

        &:last-of-type {
          padding-top: 0;
        }

        svg {
          color: $tertiary_colour;
          font-size: 20px;
          margin-right: 15px;
        }

        h1 {
          color: $tertiary_colour;
          font-size: 18px;
          margin: 0;
        }
      }

      .DataFormSectionTab {
        .tabular.menu {
          border-bottom: 1px solid $primary_colour;

          .item {
            z-index: 9;
            font-weight: bold;
            font-size: 15px;
            color: $secondary_colour;

            & .ActiveStageTab {
              color: $primary_colour;
            }

            &:hover {
              color: $primary_colour;
            }

            &.active {
              color: $tertiary_colour;
              border-left: 1px solid $primary_colour;
              border-top: 1px solid $primary_colour;
              border-right: 1px solid $primary_colour;
            }

            &:first-of-type {
              &.active {
                border-left: none;
                border-top-left-radius: 0;
              }
            }
          }
        }
        .bottom {
          border: none;
          padding: 20px;

          & > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .field {
              width: 100%;

              @media (min-width: 769px) {
                &.halfWidth {
                  width: 49%;
                }

                &.thirdWidth {
                  width: 32%;
                }
              }
            }
          }
        }

        .DataTableTitleContainer {
          svg {
            font-size: 20px;
            margin-right: 15px;
          }

          h1 {
            font-size: 18px;
          }
        }
      }
    }
  }

  .actions {
    background: $white !important;
  }
}

.DataAttachmentsContainer {
  .DataTableTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 2;

    color: $tertiary_colour;

    svg {
      font-size: 20px;
      margin-right: 15px;
    }

    h1 {
      font-size: 18px;
      margin: 0;
    }
  }

  .DataTableContainer {
    .DataTableHeader {
      margin-bottom: 10px;
    }
  }
}

.PromptModalContainer {
  border-radius: 0.28571429rem !important;

  & > .header {
    background: $primary_colour !important;
    color: $white !important;
    border-bottom: none !important;
  }

  .actions {
    background: $white !important;
  }

  .PromptModalFiles {
    text-align: center;

    .PromptModalFilesThumb {
      width: 160px;
      height: 105px;
      border-radius: 5px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      overflow: hidden;
    }
  }

  .ui.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .field {
      width: 100%;

      @media (min-width: 769px) {
        &.halfWidth {
          width: 49%;
        }

        &.thirdWidth {
          width: 32%;
        }
      }
    }
  }
}

.FormFieldTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h3 {
    font-size: 15px;
    margin: 0 !important;
  }

  span {
    font-weight: 800;
    color: $midGrey;
  }
}

.SignatureViewContainer {
  .divider {
    margin: 30px 0 30px;

    h3 {
      color: $tertiary_colour;
      font-size: 18px;
      text-transform: none;
      letter-spacing: 0;
    }
  }

  .SignatureViewWrapper {
    border: 1px solid $primary_colour;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .SignatureInputRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div {
      margin-right: 20px;
      margin-bottom: 20px;
      flex-grow: 1;

      &:last-of-type {
        margin-right: 0;
      }

      .SignatureImage {
        width: 50%;
        height: 320px;
        border: 1px solid $darkGrey;
        border-radius: 5px;
        margin-top: 20px;

        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
      }
    }
  }

  .StarRatingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      height: 38px;
      margin-right: 20px;
      font-size: 1.5em;
    }

    .yellow {
      color: $yellow;
    }
    .grey {
      color: $midGrey;
    }

    p {
      font-weight: bold;
    }
  }
}

.Checklist {
  .DataTableContainer {
    button.ui.small.icon.left.labeled.button {
      width: 100% !important;

      &.spaceBelow {
        margin-bottom: 1em;
      }
    }
  }
}

.LocationPicker {
  & > .DataTableContainer {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 15px;
  }

  .divider {
    margin: 30px 0 20px;

    h3 {
      color: $tertiary_colour;
      font-size: 18px;
      text-transform: none;
      letter-spacing: 0;
    }
  }
}

.Checklist {
  & > .DataTableContainer {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 15px;
  }

  .divider {
    margin: 30px 0 20px;

    h3 {
      color: $tertiary_colour;
      font-size: 18px;
      text-transform: none;
      letter-spacing: 0;
    }
  }
}

.DropdownLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    &.TabHeader {
      font-size: 15px;
    }
  }

  div {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;

    &.grey {
      background-color: $midGrey;
    }

    &.blue {
      background-color: $blue;
    }

    &.yellow {
      background-color: $yellow;
    }

    &.orange {
      background-color: $orange;
    }

    &.red {
      background-color: $red;
    }
    &.green {
      background-color: $green;
    }
  }
}

.DropdownContent {
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    h3 {
      font-size: 12px;
      color: $midGrey;
      margin-right: 10px;
      margin-bottom: 0;
    }

    p {
      flex-grow: 2;
    }
  }
}

.DropdownLookup {
  border-radius: 5px 5px 0 0 !important;

  &.error {
    border-radius: 5px !important;
  }
}

.LookupAddress {
  padding: 15px 10px 10px;
  border-radius: 0 0 5px 5px;
  border: 1px solid $darkGrey;
  border-top: none;

  & > h4 {
    color: $tertiary_colour;
  }

  & > div {
    display: flex;
    flex-direction: row;

    .LookAddressColumn {
      display: flex;
      flex-direction: column;

      margin-right: 30px;

      h4 {
        margin-bottom: 10px;
      }

      div {
        margin-bottom: 10px;
      }
    }
  }
}

.DataFormSwitchContainer {
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    span {
      margin-right: 10px;
    }
  }
}

.IntegrationWrapper {
  border: 1px solid $darkGrey;
  border-radius: 5px;
  padding: 25px;

  .Steps {
    display: flex;
    flex-wrap: wrap;

    .Step {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  .StatusIcon {
    display: inline-block;
    background: $red;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;

    &.red {
      background-color: $red;
    }
    &.green {
      background-color: $green;
    }
  }
}
